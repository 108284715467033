import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import Log from '../components/lib/Log';
import { makeUUID } from '../components/lib/Utils';
// eslint-disable-next-line import/no-internal-modules
import '@askyourteam-nz/react-library/dist/polyfills';

sessionStorage.setItem('sessionID', makeUUID());
window.metrics = {
  track: function (...args) {
    args[1] = args[1] || {};
    args[1].session_id = sessionStorage.getItem('sessionID');
    args[1] = { ...args[1], ...window.metrics_props };
    return analytics.track(...args);
  }
};

window.APP_ENV = (() => {
  switch(true) {
    case (/app.askyourteam.com/g).test(location.hostname): return 'production';
    case (/app.training-askyourteam.net/g).test(location.hostname): return 'training';
    case (/app.staging-askyourteam.net/g).test(location.hostname): return 'staging';
    default: return 'development';
  }
})();

Sentry.init({
  dsn: 'https://87d28a18313b49f5b8b632dc14e366a2@sentry.io/1759182',
  release: process.env.BUILDKITE_COMMIT || '0.0.1-dev',
  environment: process.env.RAILS_ENV,
  tracesSampleRate: 0.01,
  beforeSend: (event, hint) => {
    if (event.exception.values[0].value == 'ResizeObserver loop limit exceeded') return;
    if (['development', 'test'].includes(process.env.RAILS_ENV)) {
      console.error(hint.originalException); // eslint-disable-line no-console
      console.log('Sentry', event, hint); // eslint-disable-line no-console
      return;
    }
    return event;
  },
  integrations: [
    new Sentry.Integrations.Breadcrumbs({ console: false }),
    new Integrations.ExtraErrorData(),
    new TracingIntegrations.BrowserTracing()
  ]
});

window.Promise._unhandledRejectionFn = error => Sentry.captureException(error);

window.Promise = class extends Promise {
  constructor(...args) {
    return super(...args); // eslint-disable-line constructor-super
  }
  catch(func, ...args) {
    let loggingFunc = (error = {}, ...args) => {
      Log.warn('Promise caught', error);
      Log.breadcrumb('Promise caught', error.message, Sentry.Severity.Error);
      return func(error, ...args);
    };
    return super.catch(loggingFunc, ...args);
  }
};
